import { useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";
import { Button, DataStatus, Tooltip } from "@thedealersconcierge/components";
import { permissionChecker } from "@thedealersconcierge/lib/auth";
import { useAtom } from "jotai";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  ComplianceTriple,
  FormSubmissionStatus,
  FormSubmissionType,
  TransactionLifecycle,
} from "~/__generated__/backend/zeus";
import Banner from "~/components/Banner";
import Spinner from "~/components/Spinner";
import withSuspense from "~/components/withSuspense";
import { gqlQueryClient } from "~/lib/backend";
import { getDateFromUnknown } from "~/lib/helpers";
import { DealershipQueryType } from "~/query/dealershipQuery";
import { dealershipAtom } from "~/state";
import {
  TransactionQueryType,
  refetchTransactionQuery,
} from "../_queries/transactionQuery";
import ReqSignaturesConfirmModal from "./ReqSignaturesConfirmModal";

const TransactionDocuments: FC<{
  transactionId: string;

  /**
   * @deprecated
   */
  transaction: TransactionQueryType["transaction"];

  /**
   * @deprecated
   */
  dealership: DealershipQueryType["dealership"];
}> = ({ transactionId, transaction, dealership }) => {
  const [prePurchaseModalIsOpen, setPrePurchaseModalIsOpen] = useState(false);
  const [postPurchaseModalIsOpen, setPostPurchaseModalIsOpen] = useState(false);
  const [activeDealershipPerms] = useAtom(dealershipAtom);
  const { t } = useTranslation();

  // Left here intentionally to exemplify how to debug and test suspense
  // useSuspenseDebug(2000);

  const complianceInfo = useSuspenseQuery({
    queryKey: ["complianceInfo", transactionId],
    queryFn: async () =>
      gqlQueryClient()({
        transaction: [
          { id: transactionId },
          {
            complianceFormsData: {
              prePurchasePreCondition: {
                hasBuyerSubmittedHca: true,
                hasCoBuyerSubmittedHca: true,
                hasStaffAssigned: true,
                hasVehicleForPurchase: true,
              },
            },
          },
        ],
      }),
  });

  const [statusQueryPrePurchase, statusQueryPostPurchase] = useSuspenseQueries({
    queries: [
      {
        // Note: This needs to be updated now we have the collections
        queryKey: ["statusQueryPrePurchase", transaction?.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction?.id ?? "no-transaction-id" },
              {
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.PRE_PURCHASE,
                      },
                      status: {
                        notIn: [
                          FormSubmissionStatus.FINAL,
                          FormSubmissionStatus.CANCELLED,
                        ],
                      },
                    },
                  },
                  {
                    __directives: `@include(if:${permissionChecker("viewDealJacket", activeDealershipPerms?.activeDealershipPerms)})`,
                    totalCount: true,
                  },
                ],
              },
            ],
          }),
      },
      {
        queryKey: ["statusQueryPostPurchase", transaction?.id],
        queryFn: async () =>
          gqlQueryClient()({
            transaction: [
              { id: transaction?.id ?? "no-transaction-id" },
              {
                // Check if there are any missing documents to be signed (for green check mark)
                formSubmissions: [
                  {
                    filter: {
                      type: {
                        equals: FormSubmissionType.POST_PURCHASE,
                      },
                      status: {
                        not: { equals: FormSubmissionStatus.FINAL },
                      },
                    },
                  },
                  {
                    totalCount: true,
                  },
                ],
              },
            ],
          }),
      },
    ],
  });

  if (!complianceInfo.data.transaction) {
    return <Banner variant="WARNING"> No such transaction</Banner>;
  }

  const complInfo = complianceInfo.data.transaction.complianceFormsData;

  // Compute the current state of the pre purchase docs
  console.log(
    "statusQueryPrePurchase",
    statusQueryPrePurchase.data.transaction?.formSubmissions?.totalCount
  );
  const completedPrePurchaseDocuments = transaction?.requestedPrePurchaseFormsAt
    ? statusQueryPrePurchase.data.transaction?.formSubmissions?.totalCount === 0
      ? { status: "COMPLETE" as const, text: "All is good" }
      : {
          status: "INCOMPLETE" as const,
          text: "Pre-purchase documents are requested but not yet signed",
        }
    : {
        status: "INCOMPLETE" as const,
        text: "Pre-purchase documents are not requested",
      };

  // Compute current state for pre purchase docs
  const completedPostPurchaseDocuments =
    transaction?.requestedPostPurchaseFormsAt
      ? statusQueryPostPurchase.data.transaction?.formSubmissions
          ?.totalCount === 0
        ? { status: "COMPLETE" as const, text: "All is good" }
        : {
            status: "INCOMPLETE" as const,
            text: "Post-purchase documents are requested but not yet signed",
          }
      : {
          status: "INCOMPLETE" as const,
          text: "Post-purchase documents are not requested",
        };

  const enablePrePurchaseButton =
    // Transaction needs to hold a vehicle
    complInfo.prePurchasePreCondition.hasVehicleForPurchase &&
    // The buyer needs to have submitted credit application
    complInfo.prePurchasePreCondition.hasBuyerSubmittedHca !==
      ComplianceTriple.FALSE &&
    // The co-buyer, if present, needs to have submitted credit application
    complInfo.prePurchasePreCondition.hasCoBuyerSubmittedHca !==
      ComplianceTriple.FALSE &&
    // All staff, except BDC, has to be assigned
    complInfo.prePurchasePreCondition.hasStaffAssigned &&
    // Post purchase forms has not yet been requested
    !transaction?.requestedPostPurchaseFormsAt;

  // same, but for post
  // Note: As pre-purchase docs were completed things like a vehicle already exists and should not be
  // tested again.
  const disablePostPurchaseButton =
    Boolean(transaction?.requestedPostPurchaseFormsAt) ||
    completedPrePurchaseDocuments.status !== "COMPLETE";

  const canRequestSignature = permissionChecker(
    "requestSignatures",
    activeDealershipPerms?.activeDealershipPerms
  );

  const requestedPrePurchaseFormsAt = getDateFromUnknown(
    transaction?.requestedPrePurchaseFormsAt
  );

  const requestedPostPurchaseFormsAt = getDateFromUnknown(
    transaction?.requestedPostPurchaseFormsAt
  );

  return (
    <>
      <ReqSignaturesConfirmModal
        lastRequestedAt={requestedPrePurchaseFormsAt ?? "NEVER"}
        forLifeCycle={TransactionLifecycle.PRE_PURCHASE}
        transaction={transaction}
        isOpen={prePurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPrePurchaseModalIsOpen(false);
        }}
        onSuccess={() => {
          toast.success(
            "Successfully requested signatures for pre-purchase documents"
          );
          setPrePurchaseModalIsOpen(false);

          // Fire and forget
          Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            statusQueryPrePurchase.refetch(),
          ]).catch(console.error);
        }}
        onCancel={() => {
          setPrePurchaseModalIsOpen(false);
        }}
      />

      <ReqSignaturesConfirmModal
        lastRequestedAt={requestedPostPurchaseFormsAt ?? "NEVER"}
        forLifeCycle={TransactionLifecycle.POST_PURCHASE}
        transaction={transaction}
        isOpen={postPurchaseModalIsOpen}
        transactionId={transaction?.id ?? "no-transaction-id"}
        onError={(msg) => {
          toast.error(`An error happened: ${msg}`);
          setPostPurchaseModalIsOpen(false);
        }}
        onSuccess={() => {
          toast.success(
            t("Successfully requested signatures for post-purchase documents")
          );
          setPostPurchaseModalIsOpen(false);

          // Fire and forget
          Promise.all([
            refetchTransactionQuery(transaction?.id ?? "no-transaction-id"),
            statusQueryPostPurchase.refetch(),
          ]).catch(console.error);
        }}
        onCancel={() => {
          setPostPurchaseModalIsOpen(false);
        }}
      />

      {dealership?.hasEnabledComplianceForms && (
        <div className="rounded-2xl bg-white shadow-md p-8 space-y-8">
          <div className="flex flex-row justify-between">
            <h2 className="text-heading-1">Forms</h2>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center">
              {/**
               * Pre-purchase forms
               */}
              <div className="flex flex-row space-x-6 w-1/2">
                <div className="relative">
                  <Tooltip
                    hide={completedPrePurchaseDocuments.status === "COMPLETE"}
                    anchor={
                      <div>
                        <DataStatus
                          status={completedPrePurchaseDocuments.status}
                        />
                      </div>
                    }
                    content={<p>{completedPrePurchaseDocuments.text}</p>}
                  />
                </div>

                <div className="text-body text-dark-gray">
                  {t("Pre-Purchase Forms")}
                </div>
              </div>

              {canRequestSignature && (
                <Tooltip
                  hide={enablePrePurchaseButton}
                  anchor={
                    <Button
                      label={
                        requestedPrePurchaseFormsAt
                          ? t("Request Signatures Again")
                          : t("Request Signatures")
                      }
                      variant="GHOST"
                      size="SMALL"
                      onClick={() => {
                        setPrePurchaseModalIsOpen(true);
                      }}
                      disabled={!enablePrePurchaseButton}
                      dataTestId="request-pre-purchase-signature-button"
                    />
                  }
                  content={
                    <div className="flex flex-col space-y-2">
                      {!complInfo.prePurchasePreCondition
                        .hasVehicleForPurchase && (
                        <DataStatus
                          label={t("Transaction has no vehicle")}
                          status={"INCOMPLETE"}
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                      {!complInfo.prePurchasePreCondition.hasStaffAssigned && (
                        <DataStatus
                          label={t("Staff is assigned")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                      {complInfo.prePurchasePreCondition
                        .hasBuyerSubmittedHca === ComplianceTriple.FALSE && (
                        <DataStatus
                          label={t("Missing buyers credit application")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}

                      {complInfo.prePurchasePreCondition
                        .hasCoBuyerSubmittedHca === ComplianceTriple.FALSE && (
                        <DataStatus
                          label={t("Missing co-buyers credit application")}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}

                      {Boolean(transaction?.requestedPostPurchaseFormsAt) && (
                        <DataStatus
                          label={t(
                            "Post-purchase forms were already requested"
                          )}
                          status="INCOMPLETE"
                          size="SMALL"
                          labelClassName="text-inverse"
                        />
                      )}
                    </div>
                  }
                />
              )}
            </div>

            {dealership.hasEnabledPostPurchaseDocs && (
              <div className="flex flex-row items-center">
                {/**
                 * Post-purchase forms
                 */}
                <div className="flex flex-row space-x-6 w-1/2">
                  <div className="relative">
                    <Tooltip
                      hide={
                        completedPostPurchaseDocuments.status === "COMPLETE"
                      }
                      anchor={
                        <div>
                          <DataStatus
                            status={completedPostPurchaseDocuments.status}
                          />
                        </div>
                      }
                      content={<p>{completedPostPurchaseDocuments.text}</p>}
                    />
                  </div>

                  <div className="text-body text-dark-gray col-span-3">
                    Post-Purchase Forms
                  </div>
                </div>

                {canRequestSignature && (
                  <Tooltip
                    hide={!disablePostPurchaseButton}
                    anchor={
                      <Button
                        label="Request Signatures"
                        variant="GHOST"
                        size="SMALL"
                        onClick={() => {
                          setPostPurchaseModalIsOpen(true);
                        }}
                        disabled={disablePostPurchaseButton}
                        dataTestId="request-post-purchase-signature-button"
                      />
                    }
                    content={
                      <div className="flex flex-col space-y-2">
                        {Boolean(transaction?.requestedPostPurchaseFormsAt) && (
                          <DataStatus
                            label={t(
                              "Post-purchase forms were already requested"
                            )}
                            status="INCOMPLETE"
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        )}

                        {completedPrePurchaseDocuments.status !==
                          "COMPLETE" && (
                          <DataStatus
                            label={t(
                              "Pre-purchase forms has not yet been signed by the customer"
                            )}
                            status={completedPrePurchaseDocuments.status}
                            size="SMALL"
                            labelClassName="text-inverse"
                          />
                        )}
                      </div>
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default withSuspense(
  TransactionDocuments,
  <div className="rounded-2xl bg-white shadow-md flex size-full justify-center align-middle">
    <Spinner />
  </div>
);
